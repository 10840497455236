'use strict';

/**
 *
 * Global kit functions
 * They can be called from any JS Class, provided they are imported
 *
 */

export class Kit {

    protected nbProduct;
    protected nbTreatment;
    protected treatmentCurrentBlock;
    protected treatmentOtherBlock;
    protected productBlock;

    constructor() {
        this.nbProduct = $('.kitFormTreatment .kitFormTreatment__product').length;
        this.nbTreatment = $('.kitFormTreatment .kitFormTreatment__block').length;
        this.treatmentCurrentBlock = $('.kitFormTreatment__clone--currentYear .kitFormTreatment__block').clone();
        this.treatmentOtherBlock = $('.kitFormTreatment__clone--otherYear .kitFormTreatment__block').clone();
        this.productBlock = $('.kitFormTreatment__clone--currentYear .kitFormTreatment__product').clone();
        this.initFrDatepicker();
        this.displayStepForm();
        this.summaryAccordion();
        this.makeForm();
        this.previousForm();
        this.nextForm();
        this.centerCurrentItemForTimeline();
        this.loadTabsForm();
        this.addOrRemoveTreatment();
        this.addOrRemoveProduct();
        this.changeTitleTreatmentBlock();
        this.loaders();
        this.dashboardAccordion();
        this.progressBar();
        this.toast();

        this.initTrigger();
    }

    initFrDatepicker = (): void => {
        $.datepicker.regional['fr'] = {
            clearText: 'Effacer', clearStatus: '',
            closeText: 'Fermer', closeStatus: 'Fermer sans modifier',
            prevText: '<Préc', prevStatus: 'Voir le mois précédent',
            nextText: 'Suiv>', nextStatus: 'Voir le mois suivant',
            currentText: 'Courant', currentStatus: 'Voir le mois courant',
            monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
                'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
            monthStatus: 'Voir un autre mois', yearStatus: 'Voir un autre année',
            weekHeader: 'Sm', weekStatus: '',
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
            dayStatus: 'Utiliser DD comme premier jour de la semaine', dateStatus: 'Choisir le DD, MM d',
            dateFormat: 'dd/mm/yy', firstDay: 0,
            initStatus: 'Choisir la date', isRTL: false
        };
        $.datepicker.setDefaults($.datepicker.regional['fr']);
    }

    initResponsive = (): void => {
        this.centerTabsOnMobile();
        this.centerCurrentItemForTimeline();
        this.responsiveOrderSummary();
        $('.kitInfo--open').each((index, element) => {
            $('body').trigger('kit:progressBar', [$(element)]);
        });
    }

    initTrigger = (): void => {
        $('body')
            .trigger('kitForm:init', [$('.kitForm')])
            .trigger('kitForm:tabs')
            .trigger('kitForm:accordion');
    }

    centerCurrentItemForTimeline = (): void => {
        const $timeline = $('.kitTimeline');
        if ($timeline.length !== 0) {
            this.centerScrollableItem($timeline, $('.kitTimeline__item--current'));
        }
    }

    centerTabsOnMobile = (): void => {
        const $tabLink = $('.kitTabs');
        if (matchMedia('(max-width: 38em').matches && $tabLink.length !== 0) {
            this.centerScrollableItem($tabLink, $('.kitTabs__cta--active').parent());
        }
    }

    centerScrollableItem = ($container: any, $item: any): void => {
        const position = $item.offset().left - (($(window).width() - $item.outerWidth()) / 2);
        $container.animate({
            scrollLeft: $container.scrollLeft() + position
        }, 200);
    }

    currentForm = (idForm): void => {
        const currentClass = 'kitTimeline__item--current';
        $(idForm).fadeToggle(200, () => {
            $('.kitTimeline__cta[data-form=' + idForm + ']').parent().toggleClass(currentClass);
            $('html, body').animate({
                scrollTop: $('.kitTimeline').offset().top - 100
            }, 750);
            $(idForm).find('input:visible:first').focus();
        });
        setTimeout(() => {
            this.centerCurrentItemForTimeline();
        }, 300);

    }

    displayStepForm = (): void => {
        const idForm = $('.kitTimeline__item--current').find('.kitTimeline__cta').attr('data-form');
        $(idForm).fadeIn(200);
        $(idForm).find('input:visible:first').focus();
    }

    summaryAccordion = (): void => {
        $('body').on('kitForm:accordion', () => {
            $('.kitSummary__block').on('click', (e) => {
                $(e.currentTarget).toggleClass('open');
            });
        });
    }

    loadTabsForm = (): void => {
        const $tabs = $('#kitTreatmentTabs');
        if ($tabs.length !== 0) {
            $('body').on('kitForm:tabs', () => {
                $tabs.children().each((index, element) => {
                    const idTab = $(element).attr('data-tab-id');
                    if ($(element).hasClass('kitForm__tabs-item--active')) {
                        $(idTab).show();
                    } else {
                        $(idTab).hide();
                    }
                });
            });
            $('.kitForm__tabs-item').on('click', (e) => {
                $('.kitForm__tabs-item--active').removeClass('kitForm__tabs-item--active');
                $(e.currentTarget).addClass('kitForm__tabs-item--active');
                $('body').trigger('kitForm:tabs');
            });
        }
    }

    makeForm = (): void => {
        const dateToday = new Date();
        $('body').on('kitForm:init', (e, $container) => {
            $container.find('input, textarea').uniform({
                checkboxClass: 'kitCheckBox',
                inputClass: 'kitInput',
                selectClass: 'kitSelect',
                radioClass: 'kitRadio'
            });
            (<any>$container.find('.select--optional, .select--required')).chosen({
                placeholder_text_single: ' ',
                no_results_text: 'Aucun résultat ne correspond',
                allow_single_deselect: true,
                width: '100%',
                case_sensitive_search: false
            });
            $container.find('#samplingDate').datepicker({
                dateFormat: 'dd-mm-yy',
                minDate: dateToday,
            });
            $container.find('.kitDatepicker').datepicker({
                dateFormat: 'dd-mm-yy',
            });
        });
    }

    previousForm = (): void => {
        $('.kitForm__footer-previous').on('click', (e) => {
            e.preventDefault();
            const $currentForm = $(e.currentTarget).parents('.kitForm');
            const $previousForm = $currentForm.prev();
            this.currentForm('#' + $currentForm.attr('id'));
            this.currentForm('#' + $previousForm.attr('id'));
        });
    }

    nextForm = (): void => {
        $('body').on('kitForm:next', (e, idForm) => {
            const $nextForm = $(idForm).next();
            if ($nextForm.length !== 0) {
                $('.kitTimeline__cta[data-form=' + idForm + ']').parent().addClass('kitTimeline__item--active');
                this.currentForm('#' + $nextForm.attr('id'));
                this.currentForm(idForm);
            }
        });
    }

    addOrRemoveTreatment = (): void => {
        $('body').on('click', '.kitFormTreatment__block-button', (e) => {
            e.preventDefault();
            const idTab = $(e.currentTarget).parents('.kitFormTreatment').attr('id');
            const currentYear = $(e.currentTarget).parents('.kitFormTreatment').hasClass('kitFormTreatment--currentYear');
            if ($(e.currentTarget).hasClass('kitFormTreatment__block-button--add')) {
                this.nbTreatment++;
                this.nbProduct++;
                const $blockToCopy = $(e.currentTarget).parents('.kitFormTreatment__block');
                const $clonedBlock = currentYear ? this.treatmentCurrentBlock.clone() : this.treatmentOtherBlock.clone();
                $clonedBlock.insertAfter($blockToCopy);
                this.cloneBlockForm($clonedBlock, false);
                $(e.currentTarget)
                    .removeClass('kitFormTreatment__block-button--add')
                    .addClass('kitFormTreatment__block-button--remove')
                    .html('Supprimer ce traitement');
                $(e.currentTarget).trigger('kitForm:init', [$clonedBlock]);
            } else {
                $(e.currentTarget)
                    .parents('.kitFormTreatment__block')
                    .remove();
            }
            $('body').trigger('treatment:change', [idTab]);
        });
    }

    addOrRemoveProduct = (): void => {
        $('body').on('click', '.kitFormTreatment__product-button', (e) => {
            e.preventDefault();
            if ($(e.currentTarget).hasClass('kitFormTreatment__product-button--add')) {
                this.nbProduct++;
                const $blockToCopy = $(e.currentTarget).parents('.kitFormTreatment__product');
                if ($blockToCopy.index() < 4) {
                    const $clonedBlock = this.productBlock.clone();
                    $clonedBlock.insertAfter($blockToCopy);
                    this.cloneBlockForm($clonedBlock, true);
                    $(e.currentTarget)
                        .removeClass('kitFormTreatment__product-button--add')
                        .addClass('kitFormTreatment__product-button--remove')
                        .html('Supprimer ce produit');
                    $(e.currentTarget).trigger('kitForm:init', [$clonedBlock]);
                }
            } else {
                $(e.currentTarget)
                    .parents('.kitFormTreatment__product')
                    .remove();
            }
        });
    }

    changeTitleTreatmentBlock = (): void => {
        $('body').on('treatment:change', (e, tabId) => {
            $('#' + tabId).find('.kitFormTreatment__block').each((index, element) => {
                const _enum = index + 1;
                $(element).find('.kitFormTreatment__block-title--incremented').html('Traitement herbicide ' + _enum);
            });
        });
    }

    cloneBlockForm = ($block, isProduct): void => {
        const tab = $block.parents('.kitFormTreatment').attr('id');
        let dynamicPart = '';
        if (isProduct) {
            const indexTreatment = $block.parents('.kitFormTreatment__block').attr('data-index');
            dynamicPart = '[' + tab + '][' + indexTreatment + '][' + this.nbProduct + ']';
        } else {
            $block.attr('data-index', this.nbTreatment);
            dynamicPart = '[' + tab + '][' + this.nbTreatment + ']';
        }
        $block.find('.kitField').each((index, element) => {
            let idElement: string = $(element).data('id') + dynamicPart;
            if ($(element).hasClass('kitFormTreatment__product-field') && !isProduct) {
                idElement += '[' + this.nbProduct + ']';
            }
            if ($(element).hasClass('kitField--radio')) {
                const $label = $(element).find('label:not([class])');
                const idElementRadio = idElement + '-' + $label.html();
                $label.attr('for', idElementRadio);
                $(element).find('radio').attr({
                    'id': idElementRadio,
                    'name': idElement
                });
            } else {
                $(element).find('label').attr('for', idElement);
                $(element).find('input, select').attr({
                    'id': idElement,
                    'name': idElement
                });
            }
        });
    }

    loaders = (): void => {
        /* TODO DEV appeler ces évènements avec en paramètre l'element concerné */
        $('body')
            .on('kitForm:saving', (e, $form) => {
                $form.find('.kitForm__footer-submit').toggleClass('kitForm__footer-submit--loading');
            })
            .on('kitForm:loading', () => {
                $('#loadingForm').toggle();
            })
            .on('kitDashboard:deleting', (e, draftBlock) => {
                $(draftBlock).find('.kitIcon__delete').toggleClass('kitIcon__delete--loading');
            });
    }


    dashboardAccordion = (): void => {
        $('.kitInfo__header').on('click', (e) => {
            $(e.currentTarget).parent().toggleClass('kitInfo--open');
            $('body').trigger('kit:progressBar', [$(e.currentTarget).parent()]);
        });
        $('.kitOrderInfo__summary-header').on('click', (e) => {
            $(e.currentTarget).parent().toggleClass('kitOrderInfo__summary--open');
        });
    }

    progressBar = (): void => {
        $('body').on('kit:progressBar', (e, parent) => {
            const width = $('.kitOrderTimeline').outerWidth();
            const height = $('.kitOrderTimeline').outerHeight();
            setTimeout(() => {
                if (matchMedia('(max-width: 50em)').matches) {
                    if (parent.hasClass('kitInfo--open')) {
                        const coord = parent.find('.kitOrderTimeline__step--current').position();
                        parent.find('.kitOrderTimeline__progressBar--active').animate({
                            bottom: height - coord.top - 20,
                            right: 'auto'
                        }, 1500);
                    } else {
                        parent.find('.kitOrderTimeline__progressBar--active').css({
                            bottom: '100%',
                            right: 'auto'
                        });
                    }
                } else {
                    if (parent.hasClass('kitInfo--open')) {
                        const coord = parent.find('.kitOrderTimeline__step--current .kitIcon__step').position();
                        parent.find('.kitOrderTimeline__progressBar--active').animate({
                            right: width - coord.left - 20,
                            bottom: 'auto'
                        }, 1500);
                    } else {
                        parent.find('.kitOrderTimeline__progressBar--active').css({
                            right: '100%',
                            bottom: 'auto'
                        });
                    }
                }
            }, 100);
        });
    }

    toast = (): void => {
        $('body').on('kit:toast', (e) => {
            $('.kitToast').addClass('kitToast--open');
            setTimeout(() => {
                $('.kitToast').removeClass('kitToast--open');
            }, 16000);
        });
        $('.kitToast__wrapper-icon').on('click', (e) => {
            $(e.currentTarget).parents('.kitToast--open').removeClass('kitToast--open');
        });
    }

    responsiveOrderSummary = (): void => {
        $('.kitOrderTimeline__step').each((index, element) => {
            const dateInfo = $(element).find('.kitOrderTimeline__step-date');
            const statusInfo = $(element).find('.kitOrderTimeline__step-status');
            const statusIcon = $(element).find('.kitIcon__status');
            if (matchMedia('(max-width: 50em)').matches) {
                if (statusInfo.prev().hasClass('kitIcon__status')) {
                    statusInfo.insertAfter(dateInfo);
                }
            } else {
                if (statusInfo.prev().hasClass('kitOrderTimeline__step-date')) {
                    statusInfo.insertAfter(statusIcon);
                }
            }
        });
    }
}
