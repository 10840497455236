'use strict';

import {Helpers} from './_helpers';
const oHelper = new Helpers();
declare const Ajax: any;
declare const window: any;
declare const grecaptcha: any;

/**
 *
 * Advice functions
 * They can be called from any JS Class, provided they are imported
 *
 */

export class Advice {

    constructor() {
        this.initAdvice();
    }

    initAdvice(): void {
        // [BLOC ADVICE]
        // question bas de page article
        if ($('.page-article').length > 0) {
            const idArticle = $('.page-article').attr('data-articleID');
            const nameEQ = 'isArticleUsefull' + idArticle + '=';
            let readCookie = '';
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1, c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                    readCookie = c.substring(nameEQ.length, c.length);
                }
            }
            if (readCookie !== '') {
                $('.js-articleAvisQuestion').hide();
            }
        }

        $('.js-articleAvisUsefull').click(function (e) {
            const $this = $(this);
            const $form = $this.parents('form');
            $('#spinner-captcha').show();
            $('.js-articleAvisUsefull').addClass('disabled');
            window.adviceVote = $this.val();
            window.isArticleAction = $form.attr('action');
            window.adviceGiven = Ajax.article_isUsefull({
                type: 'POST',
                url: window.isArticleAction,
                data: {
                    'tx_bayeragriadvice_advice[newAdvice][isArticleUsefull]': window.adviceVote
                }
            });
            window.adviceGiven.done(function (data) {
                if (data.responseStatus === 2) {
                    $('#spinner-captcha').hide();
                    $('#articleAvisCookieError').show();
                } else {
                    $('#spinner-captcha').hide();
                    if ($('#articleAvisCaptcha').length) {
                        $('.js-articleAvisCaptcha').show();
                        // oHelper.animatedScrollToAnchor('#articleAvisCaptcha');
                    } else {
                        $('.js-articleAvisComment').show();
                        $('#spinner-comment').hide();
                        oHelper.animatedScrollToAnchor('#articleAvisComment');
                    }
                    $this.addClass('active');
                    const firstStepResponse = JSON.parse(window.adviceGiven['responseText']);
                    const queryStatus = firstStepResponse['responseStatus'];
                    if (queryStatus) {
                        window.idAvis = firstStepResponse['idQuery'];
                    }
                }
            });
        });

        window.verifyCaptchaCallback = function () {
            const grecaptcha_response = grecaptcha.getResponse();
            if (grecaptcha_response.length === 0) {
                window.captchaVerified = 0;
            } else {
                window.captchaVerified = 1;
                $('.js-articleAvisCaptcha').hide();
                $('.js-articleAvisComment').show();
                $('#spinner-comment').hide();
                oHelper.animatedScrollToAnchor('#articleAvisComment');
            }
        };

        $('.js-refuseComment').on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            const action = $this.attr('href');
            $('#spinner-comment').show();
            $('.js-articleAvisFormComment').addClass('disabled');
            window.commentRefusal = Ajax.article_isUsefull({
                type: 'POST',
                url: action,
                data: {
                    'tx_bayeragriadvice_advice[refuseAdvice]': {
                        'idAvis': window.idAvis
                    }
                }
            });
            window.commentRefusal.done(function () {
                $('#spinner-comment').hide();
                $('.js-articleAvisFormComment').hide();
                $('.js-articleAvis__hideOnRefuseComment').hide();
                oHelper.animatedScrollToAnchor('#articleAvisQuestion');
            });

        });

        $('#articleAvisComment__btnSubmit').on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            const form = $this.parents('form');
            const updateAction = form.attr('action');
            const $textarea = $('.js-articleAvisCommentTextarea');
            window.comment = $textarea.val();
            if (window.comment !== '') {
                $('.js-articleAvisCommentTextareaError').hide();
                $('#spinner-comment').show();
                $('.js-articleAvisFormComment').addClass('disabled');
                window.adviceUpdated = Ajax.article_isUsefull({
                    type: 'POST',
                    url: updateAction,
                    data: {
                        'tx_bayeragriadvice_advice[updateAdvice]': {
                            'idAvis': window.idAvis,
                            'comment': window.comment,
                            'captcha': window.captchaVerified
                        }
                    }
                });
                window.adviceUpdated.done(function () {
                    $('#spinner-comment').hide();
                    window.adviceResponseStatus = JSON.parse(window.adviceUpdated['responseText']);
                    if ($('#articleAvisMail__btnSubmit').length && window.adviceResponseStatus
                        && window.adviceResponseStatus['responseStatus'] === '1') {
                        $('.js-articleAvisMail').show();
                        $('#spinner-mail').hide();
                        oHelper.animatedScrollToAnchor('#articleAvisMail');
                    } else if (window.adviceResponseStatus
                        && window.adviceResponseStatus['responseStatus'] === '1') {
                        $('#articleAvisMailConnected').show();
                    } else {
                        $('.js-articleAvisCommentTextareaError').show()
                            .html('<p class="error-advice">Un problème est survenu, veuillez contacter l\'administrateur.</p>');
                    }
                });
            } else {
                $('.js-articleAvisCommentTextareaError').show();
            }
        });

        $('.js-refuseMail').on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            const action = $this.attr('href');
            $('.js-articleAvisFormComment').addClass('disabled');
            $('#spinner-mail').show();
            window.mailRefusal = Ajax.article_isUsefull({
                type: 'POST',
                url: action,
                data: {
                    'tx_bayeragriadvice_advice[refuseAdvice]': {
                        'idAvis': window.idAvis,
                        'comment': window.comment,
                        'captcha': window.captchaVerified
                    }
                }
            });

            window.mailRefusal.done(function () {
                $('#spinner-mail').hide();
                $('.js-articleAvisMail').hide();
                $('.js-articleAvisRefuseMail').hide();
                $('.js-articleAvisFormComment').hide();
                oHelper.animatedScrollToAnchor('#articleAvisQuestion');
            });
        });

        $('#articleAvisMail__btnSubmit').on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            const form = $this.parents('form');
            const updateAction = form.attr('action');
            const mail = $('.js-articleAvisMailInput').val();
            const $form__error = $('.js-articleAvisMailError');
            if (/^[a-z0-9_\.\-\+]+@[a-z0-9_\.-]+\.[a-z]{2,6}$/.test(<any>mail)) {
                $form__error.hide();
                $('.js-articleAvisFormMail').addClass('disabled');
                $('#spinner-mail').show();
                if (window.adviceResponseStatus) {
                    window.adviceUpdated = Ajax.article_isUsefull({
                        type: 'POST',
                        url: updateAction,
                        data: {
                            'tx_bayeragriadvice_advice[updateAdvice]': {
                                'idAvis': window.idAvis,
                                'email': mail,
                                'captcha': window.captchaVerified
                            }
                        }
                    });
                } else {
                    $('#spinner-mail').hide();
                    $form__error.show().html('<p class="error-advice">Un problème est survenu, veuillez contacter l\'administrateur.</p>');
                }
                window.adviceUpdated.done(function () {
                    $('#spinner-mail').hide();
                    window.adviceResponseStatus = JSON.parse(window.adviceUpdated['responseText']);
                    if (window.adviceResponseStatus && window.adviceResponseStatus['responseStatus'] === '1') {
                        $('.js-articleAvisFormMail').addClass('disabled');
                        $('.js-articleAvisSuccessMail').show();
                        oHelper.animatedScrollToAnchor('#articleAvisMailSuccess');
                    } else {
                        $form__error.show().html('<p class="error-advice">Un problème est survenu, veuillez contacter l\'administrateur.</p>');
                    }
                });

            } else {
                if (mail !== '') {
                    $form__error.show().html('<p class="error-advice">Format Email invalide</p>');
                } else {
                    $form__error.show().html('<p class="error-advice">Veuillez renseigner votre email.</p>');
                }
            }
        });
    }
}
