'use strict';
declare var require: any;
const Masonry = require('masonry-layout');

/**
 *
 * Global helper functions
 * They can be called from any JS Class, provided they are imported
 *
 */

export class Helpers {

    triggerClick = 'click';
    down = 'mousedown';
    move = 'mousemove';
    out = 'mouseout';
    up = 'mouseup';
    public isTouch: any;

    constructor() {
        this.eventWindow();
        this.isTouch = this.isTouchDevice();
    }

    // get viewport size, without scrollbar
    // to call it from anywhere else than here : global.helpers.viewport() (ex :  global.helpers.viewport().width )
    viewport(): { width: string, height: string } {
        let e = (<any>window), a = 'inner';

        if (!('innerWidth' in window)) {
            e = HTMLElement;
            a = 'client';
            e = document.documentElement || document.body;
        }
        return {
            width: e[a + 'Width'],
            height: e[a + 'Height']
        };
    }

    // put the same min-height on several elements, based on the highest one
    // to call it from anywhere else than here : global.helpers.harmonizeHeight(selector);
    harmonizeHeight(selector: string, includePadding = true): void {
        let targetH = 0;
        this.resetHeight(selector);
        $(selector).each(function () {
            const elem = $(this);
            let h = $(elem).height();
            if (includePadding === true) {
                h = $(elem).outerHeight();
            }
            if (h > targetH) {
                targetH = h;
            }
        });
        targetH = Math.ceil(targetH);
        $(selector).css('min-height', targetH + 'px');
    }

    // reset the min-height on several elements (usually used in opposite to harmonizeHeight)
    // to call it from anywhere else than here : global.helpers.resetHeight(selector);
    resetHeight(selector: string): void {
        $(selector).css('min-height', '');
    }

    // resize and center image in its container so it occupies the whole space
    // selector is the class name of the container of the img (ex : .media-block-news)
    // to call it from anywhere else than here : global.helpers.resizeImg(selector);
    resizeImg(selector: string): void {
        $(selector).each(function () {
            const elem = $(this);
            (<any>$(elem)).imagesLoaded(function () {
                const img = $(elem).find('img');
                const wContainer = $(elem).width() + parseInt($(elem).css('padding-left')) + parseInt($(elem).css('padding-right'));
                const hContainer = $(elem).height() + parseInt($(elem).css('padding-top')) + parseInt($(elem).css('padding-bottom'));
                const ratioContainer = wContainer / hContainer;
                const ratioImg = $(img).width() / $(img).height();
                let gap = 0;
                // if the image is larger than the container
                if (ratioImg > ratioContainer) {
                    // resize thanks to CSS classes
                    $(img).removeClass('full-width');
                    $(img).addClass('full-height');
                    // center the image
                    gap = (wContainer - $(img).width()) / 2;
                    $(img).css({
                        'margin-top': '',
                        'margin-left': gap
                    });
                    // if the image is, instead, higher than the container
                } else {
                    // resize thanks to CSS classes
                    $(img).removeClass('full-height');
                    $(img).addClass('full-width');
                    // center the image
                    gap = (hContainer - $(img).height()) / 2;
                    $(img).css({
                        'margin-left': '',
                        'margin-top': gap
                    });
                }
                $(img).removeClass('faded');
            });
        });
    }

    makeCenter(): void {
        const item = '.item-center';
        const content = '.content-item-center';
        $(item).each(function () {
            const itemChild = $(this).find(content);
            const iMarginTop = ($(this).height() / 2) - (itemChild.height() / 2);
            itemChild.css('margin-top', iMarginTop);
        });
    }

    destroyCarrousel(slider: any): undefined {
        return slider.destroySlider();
    }

    truncateText(selector: string): void {

        // cas particulier petit bloc gris
        if ($(selector).hasClass('gridLarge')) {
            if (matchMedia('(max-width: 754px)').matches) {
                $(selector).find('.gridItem__2 .gridItemContent__text').hide();
            } else if (matchMedia('(max-width: 959px)').matches) {
                if ($(selector).find('.gridItem__2 .gridItemContent__title').height() < 40) {
                    $(selector).find('.gridItem__2 .gridItemContent__text').show();
                }
                if ($(selector).find('.gridItem__3 .gridItemContent__title').height() < 55) {
                    $(selector).find('.gridItem__3 .gridItemContent__text').show();
                }
            } else if (matchMedia('(max-width: 1279px)').matches) {
                if ($(selector).find('.gridItem__5 .gridItemContent__title').height() < 40) {
                    $(selector).find('.gridItem__5 .gridItemContent__text').show();
                }
                if ($(selector).find('.gridItem__4 .gridItemContent__title').height() < 20) {
                    $(selector).find('.gridItem__4 .gridItemContent__text').show();
                }
            } else {
                if ($(selector).find('.gridItem__5 .gridItemContent__title').height() < 20) {
                    $(selector).find('.gridItem__5 .gridItemContent__text').show();
                }
                if ($(selector).find('.gridItem__4 .gridItemContent__title').height() < 20) {
                    $(selector).find('.gridItem__4 .gridItemContent__text').show();
                }
            }
        }
        if ($(selector).hasClass('gridMedium')) {
            if (matchMedia('(max-width: 754px)').matches) {
                $(selector).find('.gridItem__2 .gridItemContent__text').hide();
                $(selector).find('.gridItem__3 .gridItemContent__text').hide();
            } else if (matchMedia('(max-width: 959px)').matches) {
                if ($(selector).find('.gridItem__2 .gridItemContent__title').height() < 40) {
                    $(selector).find('.gridItem__2 .gridItemContent__text').show();
                }
                if ($(selector).find('.gridItem__3 .gridItemContent__title').height() < 40) {
                    $(selector).find('.gridItem__3 .gridItemContent__text').show();
                }
            } else if (matchMedia('(max-width: 1279px)').matches) {
            } else {
            }
        }
        if ($(selector).hasClass('gridSmall')) {

            if (matchMedia('(max-width: 754px)').matches) {
                $(selector).find('.gridItem__2 .gridItemContent__text').hide();
            } else if (matchMedia('(max-width: 959px)').matches) {
                if ($(selector).find('.gridItem__2 .gridItemContent__title').height() < 40) {
                    $(selector).find('.gridItem__2 .gridItemContent__text').show();
                }
                if ($(selector).find('.gridItem').length === 2) {
                    $(selector).addClass('gridSmall--2');
                }
            } else if (matchMedia('(max-width: 1279px)').matches) {
            }
        }
        // Truncate items
        $(selector).find('.gridItem .gridItemContent__text p').each(function () {

            const fullText = $(this).attr('data-fulltext'),
                mobileLength = parseInt($(this).attr('data-length-mobile')),
                tabletLength = parseInt($(this).attr('data-length-tablet')),
                desktopLength = parseInt($(this).attr('data-length-desktop')),
                customLength = parseInt($(this).attr('data-length-custom'));

            if (fullText !== undefined && fullText !== null) {
                const fullTextSplit = fullText.split(' '),
                    fullTextLength = fullTextSplit.length;
                let truncatedText: string;
                let hasHyphen = true;
                if (matchMedia('(max-width: 38em)').matches && mobileLength) {
                    // Mobile truncate
                    if (fullTextLength < mobileLength) {
                        hasHyphen = false;
                    }
                    truncatedText = fullTextSplit.splice(0, mobileLength).join(' ');
                } else if (matchMedia('(max-width: 58em)').matches && tabletLength) {
                    // Tablet truncate
                    if (fullTextLength < tabletLength) {
                        hasHyphen = false;
                    }
                    truncatedText = fullTextSplit.splice(0, tabletLength).join(' ');
                } else if (matchMedia('(max-width: 76em)').matches && desktopLength) {
                    // Tablet truncate
                    if (fullTextLength < desktopLength) {
                        hasHyphen = false;
                    }
                    truncatedText = fullTextSplit.splice(0, desktopLength).join(' ');
                } else {
                    // Custom or no truncate
                    if (customLength) {
                        if (fullTextLength < customLength) {
                            hasHyphen = false;
                        }
                        truncatedText = fullTextSplit.splice(0, customLength).join(' ');
                    } else {
                        truncatedText = fullText;
                        hasHyphen = false;
                    }
                }
                if (hasHyphen) {
                    truncatedText = truncatedText + '...';
                }
                // Update Selector text with Truncated content
                $(this).text(truncatedText);
            }

        });
    }

    setArticlesGridMasonry(selector: string, wCarrousel: any[][]): any[][] {
        const masonrySelector = selector;

        if (matchMedia('(max-width: 38em)').matches) {

            if ($(masonrySelector).hasClass('masonrySet')) {
                const msnry = Masonry.data($(masonrySelector)[0]);
                if (msnry) {
                    msnry.destroy();
                }
                $(masonrySelector).removeClass('masonrySet');
                // Reset masonrySelector height programmatically
                $(masonrySelector).css({
                    'height': ''
                });
            }

            if ($(masonrySelector + ' .sectionArticlesGrid__mobileCarousel').length === 0) {
                const gridItem__slider = $(masonrySelector).find('.gridItem__slider');
                // $.data($(masonrySelector)[0], 'gridItem__slider', (<any> $(gridItem__slider)).clone());
                const selectorProductSLide = 'products' + masonrySelector;
                if (wCarrousel[selectorProductSLide] !== undefined) {
                    wCarrousel[selectorProductSLide] = this.destroyCarrousel(wCarrousel[selectorProductSLide]);
                }

                if (gridItem__slider.length !== 0) {
                    gridItem__slider.find('.articleCardProductList').clone().appendTo($(masonrySelector))
                        .wrap('<div class=\'gridItem_slide gridItem gridItem__3\'></div>');
                    gridItem__slider.css({
                        'height': 0,
                    });
                }

                const msnry = $.data($(masonrySelector)[0]);
                const $carouselItems = $(masonrySelector + ' .gridItem').not('.gridItem__0 , .gridItem__slider');

                $carouselItems.wrapAll('<div class=\'sectionArticlesGrid__mobileCarousel\'></div>');

                const equalizerBlocks = $(masonrySelector + ' .sectionArticlesGrid__mobileCarousel').find('.gridItem'),
                    arrayHeight = [];

                equalizerBlocks.each(function () {
                    const blockHeight = $(this).outerHeight(false);
                    arrayHeight.push(blockHeight);
                });

                // Get highest block height from Equalizer Row
                const highestHeight = Math.max.apply(Math, arrayHeight);

                equalizerBlocks.each(function () {
                    // Set highest value as Height, for simple & double blocks
                    if ($(this).outerHeight(false) <= highestHeight) {

                        $(this).find('.gridItemContent__content').css({
                            'height': highestHeight - 2 // 2 => border
                        });
                    }
                });
            }

            let num: number;
            if (matchMedia('(max-width: 25em)').matches) {
                num = 1.1;
            } else {
                num = 1.2;
            }
            const carousel = $(masonrySelector + ' .sectionArticlesGrid__mobileCarousel');
            const w = carousel.outerWidth() / num;
            const options = {
                minSlides: num,
                maxSlides: num,
                slideWidth: w,
                pager: true,
                touchEnabled: matchMedia('(max-width: 32em)').matches,
                infiniteLoop: false,
                preloadImages: 'all',
                controls: false
            };
            const name = masonrySelector + '_gridItem__slider';
            if (wCarrousel[name] !== undefined) {
                const numCard = wCarrousel[name].getSlideCount();
                if (numCard > num) {
                    wCarrousel[name].reloadSlider(options);
                } else {
                    wCarrousel[name] = wCarrousel[name].destroySlider();
                }
            } else {
                const numCard = carousel.children().length;
                if (numCard > num) {
                    wCarrousel[name] = (<any> carousel).bxSlider(options);
                }
            }

        } else {
            // Destroy Carousel & Unwrap from bxslider
            if (wCarrousel[masonrySelector + '_gridItem__slider'] !== undefined) {
                wCarrousel[masonrySelector + '_gridItem__slider'] = this.destroyCarrousel(wCarrousel[masonrySelector + '_gridItem__slider']);
                $(masonrySelector + ' .sectionArticlesGrid__mobileCarousel .gridItem').unwrap('.sectionArticlesGrid__mobileCarousel');
                $(masonrySelector + ' .sectionArticlesGrid__mobileCarousel').remove();
                $(masonrySelector + ' .gridItem_slide').remove();
            }

            // Reset Blocks sizes
            $(masonrySelector).find('.gridItem').each(function () {
                $(this).find('.gridItemContent__content').css({
                    'height': ''
                });
            });
            $(masonrySelector).find('.gridItem__slider').css({
                'height': 'auto'
            });

            if (!$(masonrySelector).hasClass('masonrySet')) {
                const msnry = new Masonry(masonrySelector, {
                    columnWidth: '.gridSizer',
                    itemSelector: '.gridItem',
                    percentPosition: true,
                    initLayout: false
                });
                $(masonrySelector).addClass('masonrySet');
                (<any> $(masonrySelector)).imagesLoaded(function () {
                    msnry.layout();
                });
            } else {
                const msnry = Masonry.data($(masonrySelector[0]));
                if (msnry) {
                    setTimeout(function () {
                        msnry.layout();
                    }, 200);
                }
            }
        }

        return wCarrousel;
    }

    animatedScrollToAnchor(anchor?: any, offset?: any): void {
        offset = offset || 0;
        if (anchor === '#top') {
            $('html, body, .st-content').animate({
                scrollTop: 0
            }, 750);
        } else {
            if ($(anchor).length === 1) {
                $('html, body, .st-content').animate({
                    scrollTop: $(anchor).offset().top - offset
                }, 750);
            } else {
                console.log('Anchor not found');
            }
        }
    }

    isTouchDevice(): boolean {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    }

    eventWindow(): void {
        // change les event selon la presence du touch
        const isTouch = this.isTouchDevice();

        if (isTouch) {
            this.triggerClick = 'touchstart';
            this.down = 'touchstart';
            this.move = 'touchmove';
            this.out = 'touchleave';
            this.up = 'touchend';
            $('html').addClass('touchingDevice');
        }
    }

    helpersPlugin(): void {
        // module tableau responsive, sur mobile, affiche/cache les colonnes du tableau en fonction du select
        $('.js-tableRespContent').each(function () {
            const $this = $(this)
                , $tableRespSelect = $this.find('.js-tableRespSelect')
                , $tableResp = $this.find('.js-tableResp')
                , $th = $tableResp.find('thead th')
                , $row = $tableResp.find('tbody tr');

            for (let k = 0; k < $row.length; k++) {
                $tableRespSelect.append($('<option value=' + k + '>' + $row.eq(k).find('td').eq(0).html().replace(/<br>/g, '-') + '</option>'));
            }
            $tableRespSelect.change(function () {
                const $currentSelectedRow = $tableResp.find('tbody tr.active')
                    , $newSelectedRow = $row.eq(<any>$(this).val());
                $currentSelectedRow.addClass('animate');
                $newSelectedRow.addClass('animate before-animate-in');
                setTimeout(function () {
                    $currentSelectedRow.addClass('animate-out');
                    $newSelectedRow.addClass('animate-in');
                    setTimeout(function () {
                        $currentSelectedRow.removeClass('active animate animate-out');
                        $newSelectedRow.removeClass('animate animate-in before-animate-in').addClass('active');
                    }, 375);
                }, 50);
            });
            for (let i = 0; i < $row.length; i++) {
                for (let j = 0; j < $th.length; j++) {
                    $row.eq(i).find('td').eq(j).prepend($('<div class=\'tableResp__tdTitle\'></div>').html($th.eq(j).html()));
                }
            }
            // si tableau mono ligne
            if ($this.find('tbody tr').length <= 1) {
                $this.addClass('has-no-select');
            }
        });
    }
}
