;
(function ($, window) {

    'use strict';

    $.fn.stickyNavbar = function (prop) {

        let options = $.extend({
            header: '.header',
            activeClass: 'active',
            attachActiveClassTo: 'li',
            animationDuration: 500,
            easing: 'swing',
            disableOnMobile: true,
            mobileWidth: 640,
            wrapper: '.header__wrapper'
        }, prop);

        let header = options.header ? $(options.header) : undefined
            , navBar = $(this);

        if (navBar.length === 0) {
            return;
        }

        if (options.disableOnMobile && $(window).width() <= options.mobileWidth) {
            return;
        }

        let stickyPosition = header ? header.outerHeight(true) : 0
            , heroHeight = navBar.offset().top - stickyPosition,
            navBarHeight = navBar.outerHeight(true),
            wrapper = $(options.wrapper);

        // collect all the sections for jumping
        let sections = new jQuery();
        navBar.find('li a').map(function () {
            var id = $(this).attr('href');
            sections = sections.add($(id));
        });

        let positionCta = [];
        positionCta[0] = $('.timeline__backTop').outerWidth();
        navBar.find('.timeline__item').each(function (index) {
            positionCta[index + 1] = positionCta[index] + $(this).outerWidth();
        });

        // because we have header/banner so there is offset for sections
        var sectionsOffset = stickyPosition + navBarHeight + 2;

        // the active menu in navigator
        var activeOne;


        function initSecondaryNav() {
            navBar.before('<div class="nav-placeholder"></div>');
            $('.nav-placeholder').css({
                'padding-bottom': navBarHeight + 'px',
                'height': '0px',
                'z-index': '-9999'
            });

            navBar.css('position', 'fixed').css('margin-top', -navBarHeight + 'px');
            scrollCallback();
        }

        function addActiveState(sections) {

            var windowScrollHeight = $(window).scrollTop();
            sections.each(function (index) {
                    var top = $(this).offset().top
                        , bottom = $(this).outerHeight(true) + top;

                    if (index !== sections.length - 1) {
                        var topNext = sections.eq(index + 1).offset().top;
                    }


                    windowScrollHeight = $(window).scrollTop();

                    if (
                        (windowScrollHeight > top - sectionsOffset && windowScrollHeight < bottom - sectionsOffset)
                        || (topNext !== undefined && windowScrollHeight > top - sectionsOffset && windowScrollHeight < topNext - sectionsOffset)
                        || (index === 0 && windowScrollHeight < top - sectionsOffset)
                        || (index === sections.length - 1 && windowScrollHeight > bottom - sectionsOffset)
                    ) {
                        activeOne = options.attachActiveClassTo === 'a' ?
                            navBar.find('li a[href~="#' + this.id + '"]')
                            : navBar.find('li a[href~="#' + this.id + '"]').parents('li');
                        if (!activeOne.hasClass(options.activeClass)) {
                            $('.' + options.activeClass).removeClass(options.activeClass);
                            activeOne.addClass(options.activeClass);
                            if (activeOne.offset().left !== 0) {
                                wrapper.animate({
                                    scrollLeft: positionCta[$('.timeline__cta').index($('.timeline__cta--active'))]
                                }, 300, function () {
                                    displayArrow();
                                }).clearQueue();
                            }
                        }
                    }

                }
            );

        }

        function navTimeline() {
            if (matchMedia('(max-width: 32em)').matches || wrapper.get(0).scrollWidth <= wrapper.innerWidth()) {
                $('.timeline__arrow').css('display', 'none');
            } else {
                displayArrow();
                let step = 230;
                if (matchMedia('(max-width: 58em)').matches) {
                    step = 170;
                }

                $('.timeline__arrow').on('click', function () {
                    let scroll = wrapper.scrollLeft();

                    if ($(this).hasClass('timeline__arrow--right')) {
                        scroll += step;
                    } else if ($(this).hasClass('timeline__arrow--left')) {
                        scroll -= step;
                    }

                    wrapper.animate({
                        scrollLeft: scroll
                    }, 500, function () {
                        displayArrow();
                    }).clearQueue();
                });
            }
        }

        function displayArrow() {
            if (matchMedia('(max-width: 32em)').matches || wrapper.get(0).scrollWidth <= wrapper.innerWidth()) {
                $('.timeline__arrow').css('display', 'none');
            } else {
                const $first = wrapper.find('.timeline__backTop');
                const $last = wrapper.find('.timeline__item:last-child');
                $('.timeline__arrow').css('display', 'flex');
                if ($first.offset().left === 0) {
                    $('.timeline__arrow--left').css('display', 'none');
                }
                if (Math.round(wrapper.offset().left + wrapper.outerWidth(true))
                    === Math.round($last.offset().left + $last.outerWidth(true))) {
                    $('.timeline__arrow--right').css('display', 'none');
                }
            }
        }


        function positionNavBar() {
            if ($(window).scrollTop() >= 0) {
                $(window).scrollTop() < heroHeight
                    ? navBar.css('margin-top', -navBarHeight - $(window).scrollTop() + 'px')
                    : navBar.css('margin-top', -navBarHeight - heroHeight + 'px');
            }
        }

        function scrollCallback() {
            positionNavBar();
            // put this into next tick
            setTimeout(function () {
                addActiveState(sections);
            }, 1);
        }

        function smoothScroll(e) {
            e.preventDefault();
            navBar.find('li').blur();
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').stop().animate({
                    scrollTop: target.offset().top - stickyPosition - navBarHeight
                }, {
                    duration: options.animationDuration,
                    easing: options.easing
                });
            }
        }


        initSecondaryNav();

        navTimeline();
        $(window).on('scroll', scrollCallback);
        $(this).find(' a[href*=#]:not([href=#])').on('click', smoothScroll);
    };

    $.fn.destroyNavbar = function () {
        $('.nav-placeholder').remove();
        $(this).css('position', 'relative').css('margin-top', 0);
    };
})
(jQuery, window, document);