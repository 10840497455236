'use strict';

import {Advice} from './_advice';
import {Helpers} from './_helpers';
import {Kit} from './_kit';
import '../assets/js_libs/jquery.uniform.min.js';
import '../assets/js_libs/jqueryui.min.js';
import '../assets/js_libs/jquery.stickyNav.custom.js';
import '../assets/js_libs/jquery.multiselect.js';

const oHelper = new Helpers();
const oAdvice = new Advice();
const oKit = new Kit();

declare const Weather: any;

/*================== BayerAgri ==========================*/
class BayerAgri {

    // Variables
    public wWidth: string;
    public wHeight: string;
    public currentWidth: string;
    public currentHeight: string;
    public timerResponsive: number;
    public wScroll: number;
    public wCarrousel: any[][];
    public $topTrigger: any;

    constructor() {
        this.initVariables();
        this.init();
        this.initMenu();
        this.makeResponsive();
        this.widgetSlide();
        this.goToTop();
        this.makeUniform();
        this.wizzardEvent();
        this.definitionEvent();
        this.tooltipsEvent();
        this.slideDownEvent();
        this.widgetCard();
        this.eventVoCulture();
        this.initSearch();
        this.enhancejQueryUI(); // search autocomplete
        this.stickyBSI();
        this.imageFullSize();
        this.orderedList();
        this.scrollTo();
        this.formCampaign();
        Weather.init();
        this.scrollProductTable();
        this.viewMoreBlockLanding();
        this.viewMoreIntroLanding();
        this.wrapBlockLanding();
    }

    makeResponsive(): void {
        this.widgetListCard();
        this.widgetCarouselIcon();
        this.otherPublicationCarousel();
        this.linkedThemesCarousel();
        this.gridArticle();
        this.mooveBreadcrumb();
        this.mooveProfile();
        this.mooveTimeline();
        this.carouselSync();
        this.widgetFichePratique();
        this.respImage();
        this.respImageSlide();
        this.respImageEngagement();
        this.truncateIntroHome();
        this.tabSize();
        this.imageEPI();
        this.truncate('.nav1__cta--connect > p');
        this.truncate('.headerPageBlue__shade-text > p');
        this.truncate('.wPush__shade-text > p');
        this.truncate('.headerPageLarge__text');
        this.truncate('.customize__button > p');
        this.truncate('.towns__value-name');
        this.truncate('.landing__intro > p');
        oHelper.harmonizeHeight('.wTwitter__item');
        oHelper.harmonizeHeight('.compte .tabs li');
        Weather.initForResponsive();
        oKit.initResponsive();
        this.slideLanding();
    }

    initVariables(): void {
        this.wCarrousel = [];
        this.currentWidth = this.wWidth = oHelper.viewport().width;
        this.currentHeight = this.wHeight = oHelper.viewport().height;
        this.wScroll = $(window).scrollTop();
    }

    init(): void {
        const hash = $(location).attr('hash');
        if (hash !== '') {
            if (hash.indexOf('utm_source') === -1) {
                if ($(hash).length !== 0 && $('.timeline').length !== 0) {
                    setTimeout(function () {
                        $('a[href=\'' + hash + '\']').trigger('click');
                    }, 500);
                }
                if ($(hash).length !== 0 && hash.indexOf('outils_et_services') !== -1) {
                    setTimeout(function () {
                        oHelper.animatedScrollToAnchor(hash, 100);
                    }, 500);
                }
            }
        }
        // select multiple seed
        $('[id^="selectMultiple"]').each(function () {
            if ($(this).length !== 0) {
                (<any>$(this)).multiselect({
                    texts: {
                        placeholder: 'Sélectionnez un ou des filtre(s)',
                        selectedOptions: ' filtres'
                    },
                    maxPlaceholderOpts: 1
                });
            }
        });

        // scroll
        $(window).on('scroll', function () {
            bayerAgri.wScroll = $(window).scrollTop();
            if (bayerAgri.wScroll > 70) {
                $('.menuDesktop').addClass('menuDesktop--stick');
            } else {
                $('.menuDesktop').removeClass('menuDesktop--stick');
            }

           if (bayerAgri.wScroll > 1 && !$('#body').hasClass('with-menu')) {
                $('.wrapHeadMobile').addClass('wrapHeadMobile--stick');
           } else {
                $('.wrapHeadMobile').removeClass('wrapHeadMobile--stick');
           }
        });

        // modernizr
        if (Modernizr.mq('only all')) {
            $(window).on('resize', function () {
                bayerAgri.wWidth = oHelper.viewport().width;
                bayerAgri.wHeight = oHelper.viewport().height;
                if (bayerAgri.currentHeight !== bayerAgri.wHeight || bayerAgri.currentWidth !== bayerAgri.wWidth) {
                    bayerAgri.currentHeight = bayerAgri.wHeight;
                    bayerAgri.currentWidth = bayerAgri.wWidth;
                    // timer pour le redimensionnement d'ecran
                    clearTimeout(bayerAgri.timerResponsive);
                    bayerAgri.timerResponsive = window.setTimeout(() => bayerAgri.makeResponsive(), 300);
                }
            });
        }

        // ready
        document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
            }
        };

        // init helpers function
        oHelper.helpersPlugin();
    }

    wrapBlockLanding(): void {
        if ($('.blockContent').length !== 0) {
            $('.blockContent').wrapAll('<div class="landing__blocks" ></div>');
        }
    }

    formCampaign(): void {
        const $this = this;
        const $form = $('#formCampaign');
        if ($form.length !== 0) {
            $form.find('input, select').each(function () {
                $this.floatingLabel(this);
                $(this).on('change load', function () {
                    $this.floatingLabel(this);
                });
            });
        }
    }

    scrollProductTable(): void {
        $('#tabScroll').on('click', function (e) {
            const $this = this;
            e.preventDefault();
            const myhref = $(this).attr('href');
            $('html,body').animate({scrollTop :  $(myhref).offset().top - 400}, 800);
        });
    }

    floatingLabel(selector: any): void {
        if ($(selector).val() !== '') {
            $(selector).addClass('not-empty');
        } else {
            $(selector).removeClass('not-empty');
        }
    }

    clickOutSelect(selector: any): void {
        $(window).on('click', function (event) {
            const target = $(event.target);
            if (!target.is('.wSelect__span') && !target.parent().is('.wSelect__span')) {
                selector.removeClass('wSelect__items--open');
            }
        });
    }

    imageFullSize(): void {
        $('.image__block--fullsize').each(function () {
            const background = $(this).find('img').attr('src');
            $(this).find('.image__blockImageWrapper').css('background-image', 'url(\'' + background + '\')');
        });
    }

    imageEPI(): void {
        const width = parseInt(this.wWidth);
        $('.produit__epi-wrapper').each(function () {
            let bg: string;
            if (width < 608) {
                bg = $(this).data('mobile');
            } else if (width > 608 && width < 800) {
                bg = $(this).data('tablet');
            } else {
                bg = $(this).data('desktop');
            }

            if (bg) {
                $(this).children('.produit__epi-media').attr('src', bg);
                $(this).children('.produit__epi-link').attr('href', bg);
                $(this).css('background-image', 'url(\'' + bg + '\')');
            }
        });
        if ($('.afterepi').length > 0) {
           $('.afterepi').insertAfter('.produit__epi');
        }
    }

    buildCarousel(options: any, name: string, num: number, slider: any): void {
        const carousel = this.wCarrousel[name];
        if (carousel !== undefined) {
            const numCard = carousel.getSlideCount();
            if (numCard > num) {
                this.wCarrousel[name].reloadSlider(options);
            } else {
                this.wCarrousel[name] = this.wCarrousel[name].destroySlider();
            }
        } else {
            const numCard = slider.children().length;
            if (numCard > num) {
                this.wCarrousel[name] = (<any>slider).bxSlider(options);
            }
        }
    }

    productCarousel(context: any, gridId: string): void {
        const self = this;
        context.find('.articleCard__content').each(function (index: number) {
            const slider = $(this).children('.carousel__list');
            const w = $(this).width() / 1.8;
            const options = {
                minSlides: 1.8,
                maxSlides: 1.8,
                slideWidth: w,
                pager: true,
                controls: false,
                touchEnabled: matchMedia('(max-width: 32em)').matches,
                infiniteLoop: false,
                adaptiveHeight: true,
                preloadImages: 'all'
            };
            self.buildCarousel(options, 'products#' + gridId, 1.8, slider);
        });
    }

    otherPublicationCarousel(): void {
        const num = this.getNumberOfSlide('publication');
        const carousel = $('.otherPublication__cards').children('.carousel__list');
        const w = $('.otherPublication__cards').width() / num;
        const options = {
            minSlides: num,
            maxSlides: num,
            slideWidth: w,
            pager: true,
            controls: false,
            touchEnabled: matchMedia('(max-width: 32em)').matches,
            infiniteLoop: false,
            preloadImages: 'all'
        };
        this.buildCarousel(options, 'otherPublication', num, carousel);
    }

    linkedThemesCarousel(): void {
        const self = this;
        const slider = $('.linkedThemes__cards');
        const num = this.getNumberOfSlide('problematique');
        const carousel = $('.linkedThemes__cards').children('.carousel__list');
        const w = $('.linkedThemes__cards').width() / num;
        const options = {
            minSlides: num,
            maxSlides: num,
            slideWidth: w,
            pager: true,
            controls: true,
            touchEnabled: matchMedia('(max-width: 32em)').matches,
            infiniteLoop: false,
            preloadImages: 'all',
            onSliderLoad: function () {
                self.replaceControlSlider(slider);
            }
        };
        this.buildCarousel(options, 'linkedThemes', num, carousel);
    }

    widgetSlide(): void {
        const self = this;
        if (self.wCarrousel['home'] !== undefined) {
            self.wCarrousel['home'].destroySlider();
        }
        (<any>$('.wSlide__list')).imagesLoaded(function () {
            if ($('.wSlide__item').length > 1) {
                self.wCarrousel['home'] = (<any>$('.wSlide__list')).bxSlider({
                    mode: 'fade',
                    speed: 800,
                    pause: 6000,
                    autoControls: true,
                    auto: true,
                    pager: true,
                    touchEnabled: matchMedia('(max-width: 32em)').matches,
                    controls: true,
                    nextText: '',
                    prevText: '',
                    stopAutoOnClick: 'true',
                    onSliderLoad: function () {
                        $('.wSlide__list').css('visibility', 'visible');
                    }
                });
            } else {
                $('.wSlide__list').css('visibility', 'visible');
            }
        });
    }

    widgetListCard(): void {
        const self = this;
        let num = this.getNumberOfSlide('card');
        $('.wlistCard__content').each(function (index: number) {
            const wrapper = $(this);
            const slider = wrapper.find('.wlistCard__content-slide');
            if (wrapper.parents('.kitReport__cards').length !== 0) {
                num = self.getNumberOfSlide('card-kit');
            }
            const width = (<any>slider).width() / num;
            const options = {
                minSlides: num,
                maxSlides: num,
                slideWidth: width,
                pager: true,
                touchEnabled: matchMedia('(max-width: 32em)').matches,
                infiniteLoop: false,
                onSliderLoad: function () {
                    self.replaceControlSlider(wrapper);
                }
            };
            self.buildCarousel(options, 'listCard_' + index, num, slider);
        });

    }

    widgetCarouselIcon(): void {
        const self = this;
        const num = this.getNumberOfSlide('icon');
        $('.carouselIcon__list').each(function (index: number) {
            const slider = $(this);
            const width = (<any>slider).width() / num;
            const options = {
                minSlides: num,
                maxSlides: num,
                slideWidth: width,
                pager: true,
                controls: false,
                touchEnabled: matchMedia('(max-width: 32em)').matches,
                infiniteLoop: false
            };
            self.buildCarousel(options, 'listIcon_' + index, num, slider);
        });

    }

    widgetFichePratique(): void {
        const num = this.getNumberOfSlide('fichePratiques');
        const carousel = $('.fichePratiques').find('.carousel__list');
        const w = carousel.width() / num;
        const options = {
            minSlides: num,
            maxSlides: num,
            slideWidth: w,
            pager: true,
            controls: false,
            touchEnabled: matchMedia('(max-width: 32em)').matches,
            infiniteLoop: false,
            preloadImages: 'all'
        };
        this.buildCarousel(options, 'fiche_pratique', num, carousel);
    }

    widgetCard(): void {
        const self = this;
        const num = this.getNumberOfSlide('card');
        $('.carouselRow.carousel__list').each(function (index: number) {
            const slider = $(this);
            const margin = 20;
            const width = (<any>slider).width() / num - margin;
            const options = {
                minSlides: num,
                maxSlides: num,
                slideWidth: width,
                pager: true,
                touchEnabled: matchMedia('(max-width: 32em)').matches,
                infiniteLoop: false
            };
            self.buildCarousel(options, 'card_' + index, num, slider);
        });
    }

    getNumberOfSlide(carousel: string): number {
        let num: number;
        const width = parseInt(this.wWidth);
        switch (carousel) {
            case 'card':
                num = 1.2;
                if (width > 928) {
                    num = 3;
                } else if (width > 696) {
                    num = 2;
                }
                break;
            case 'card-kit':
                num = 1.2;
                if (width > 1024) {
                    num = 4;
                } else if (width > 800) {
                    num = 3;
                } else if (width > 512) {
                    num = 2;
                }
                break;
            case 'problematique':
                num = 1.3;
                if (width > 1024) {
                    num = 4;
                } else if (width > 800) {
                    num = 3;
                } else if (width > 400) {
                    num = 2;
                }
                break;
            case 'publication':
                num = 1.3;
                if (width > 1044) {
                    num = 4;
                } else if (width > 800) {
                    num = 3;
                } else if (width > 440) {
                    num = 2;
                }
                break;
            case 'fichePratiques':
                num = 1.3;
                if (width > 928) {
                    num = 4;
                } else if (width > 800) {
                    num = 3;
                } else if (width > 696) {
                    num = 2;
                }
                break;
            case 'icon':
                num = 1;
                if (width > 800) {
                    num = 4;
                } else if (width > 400) {
                    num = 2;
                }
                break;
        }

        return num;
    }

    goToTop(): void {
        $('.timeline__backTop').on('click', function (event) {
            event.preventDefault();
            $('html,body').animate({scrollTop: 0}, 800);
        });
    }

    makeUniform(): void {
        $('.wWizzard__block input, .wWizzard__block button, .form-bsirequest input:radio, .form-bsirequest .form-submit, .ms-options input:checkbox').uniform();
    }

    eventVoCulture(): void {
        const self = this;
        (<any>$('.wCulture__block-select')).on('click', function () {
            const list = $(this).children('.wSelect__items');
            list.toggleClass('wSelect__items--open');
            if (list.hasClass('wSelect__items--open')) {
                self.clickOutSelect(list);
            }
        });
        // To select an item
        (<any>$('.wCulture__block-select')).find('.wSelect__list-item').on('click', function () {
            const href = $(this).data('href');
            window.location.href = href;
        });
    }

    wizzardEvent(): void {
        const self = this;
        const wizzard = $('.wWizzard__select');
        const span = wizzard.find('.wSelect__span span');
        // To open/close the select
        wizzard.on('click', function () {
            const list = $(this).children('.wSelect__items');
            list.toggleClass('wSelect__items--open');
            if (list.hasClass('wSelect__items--open')) {
                self.clickOutSelect(list);
            }
        });
        // Click on item
        wizzard.find('.wSelect__list-item').on('click', function () {
            const href = $(this).data('href');
            const input = $(this).data('input');
            const text = $(this).text();
            if (input !== undefined) {
                $(('.wWizzard__block--open')).each(function () {
                    $(this).removeClass('wWizzard__block--open');
                });
                $('#' + input).addClass('wWizzard__block--open');
                span.text(text);
            } else {
                const form = $('form.wWizzard');
                form.attr('action', href);
                form.submit();
            }
        });
        // Click on radio
        (<any>$('.wWizzard__block-radio input')).on('change', function () {
            const href = String($(this).val());
            if ($(this).is(':checked')) {
                const form = $('form.wWizzard');
                form.attr('action', href);
            }
        });
    }

    gridArticle(): void {
        const self = this;
        if ($('.sectionArticlesGrid').length) {
            $('.sectionArticlesGrid').each(function (index) {

                const gridID = 'masonry-' + index,
                    masonrySelector = '#' + gridID;

                $(this).attr('id', gridID);
                if ($(this).find('.carousel__list').length) {
                    self.productCarousel($(this), gridID);
                }
                self.wCarrousel = oHelper.setArticlesGridMasonry(masonrySelector, self.wCarrousel);
                oHelper.truncateText(masonrySelector);
            });
        }

    }

    mooveTimeline(): void {
        const timeline = $('.timeline');
        const placeholder = $('.nav-placeholder');
        const options = {
            header: '.wrapHeadMobile',
            activeClass: 'timeline__cta--active',
            attachActiveClassTo: 'a',
            animationDuration: 500,
            easing: 'swing',
            disableOnMobile: false,
            wrapper: '.timeline__wrapper'
        };
        if (timeline.length !== 0) {
            // Move position on page
            if (matchMedia('(max-width: 38em)').matches) {
                if (timeline.prev().hasClass('headerPage') || placeholder.prev().hasClass('headerPage')) {
                    timeline.insertBefore('.headerPage');
                }
            } else {
                if (timeline.next().hasClass('headerPage')) {
                    timeline.insertAfter('.headerPage');
                }
                options.header = '.menuDesktop';
            }
            if ($('.nav-placeholder')) {
                (<any>timeline).destroyNavbar();
            }
            (<any>timeline).stickyNavbar(options);
        }
    }

    mooveProfile(): void {
        $('.listing__cta--icons').each(function () {
            const media = $(this).children('.listing__item-media');
            const link = $(this).children('.link__text');
            const block = $(this).children('.listing__item-block');
            if (media.length !== 0) {
                // Move position on page
                if (matchMedia('(max-width: 32em)').matches) {
                    if (media.next().hasClass('listing__item-block')) {
                        media.insertAfter(link);
                    }
                } else {
                    if (media.prev().hasClass('link__text')) {
                        media.insertBefore(block);
                    }
                }
            }
        });
    }

    mooveBreadcrumb(): void {
        const breadcrumb = $('.breadcrumb');
        if (breadcrumb.length !== 0) {
            // Move position on page
            if (matchMedia('(max-width: 38em)').matches) {
                if (breadcrumb.next().length === 0) {
                    breadcrumb.insertBefore('.footer');
                }
            } else {
                if (breadcrumb.next().hasClass('footer')) {
                    breadcrumb.appendTo('header');
                }
            }
        }

    }

    initMenu(): void {
        const _params = {
            nav: '.menuDesktop .nav1',
            navItem: $('.nav1__item'),
            navItemOpen: '.nav1__item.open',
            navLevel2: '.nav1__wrapper',
            navCta: 'nav1__cta',
            navMbBtn: '.wrapHeadMobile .cta-resp',
            navMb: '.menuMobile .nav1'
        };

        let timerMenu;

        $(_params.nav).on('mouseenter focusin click', '.nav1__cta', function (event) {
            let elem = event.target;
            if ($(elem).parent().hasClass('nav1__cta--connect')) {
                elem = <any>$(elem).parent();
            }
            if (!$(elem).parent().hasClass('open')) {
                $(elem).parent().addClass('open');
                $(_params.navItemOpen).removeClass('open');
                if ($(elem).parent().find(_params.navLevel2).length > 0) {
                    timerMenu = setTimeout(function () {
                        $(_params.navItemOpen).removeClass('open');
                        $(elem).parent().toggleClass('open');
                    }, 200);
                }
            } else {
                $(elem).parent().removeClass('open');
            }
        });

        $(_params.nav).on('mouseleave', function () {
            clearTimeout(timerMenu);
            $(_params.navItemOpen).removeClass('open');
        });


        // open nav mobile
        $(_params.navMbBtn).on('click', function () {
            $('#body').toggleClass('with-menu');
        });


        // nav mobile
        $(_params.navMb).on('click', '.nav1__cta', function (event) {
            const elem = event.target;
            if ($(elem).parent().hasClass('open')) {
                $(elem).blur();
                $(elem).parent().removeClass('open');
            } else {
                $(_params.navItemOpen).removeClass('open');
                $(_params.navItemOpen).find('.nav1__cta').blur();
                if ($(elem).parent().find(_params.navLevel2).length > 0) {
                    $(elem).parent().addClass('open');
                }
            }
        });
    }

    initSearch(): void {
        $('.cta-search, .search__close').on('click', function () {
            $('#body').removeClass('with-menu');
            $('.search').toggleClass('open');
            $('.nav1__item').removeClass('open');
            $('.search__input').focus();
        });
        $(window).on('click', function (event) {
            const target = $(event.target);
            if (!target.is('.search, .cta-search, .search__close')
                && !target.parents().is('.search')
                && $('.search').hasClass('open')) {
                $('.search').removeClass('open');
            }
        });
    }

    definitionEvent(): void {
        $('.definition').each(function () {
            $(this).find('.definition__title').on('click', function () {
                $(this).toggleClass('definition__title--close');
            });
        });
    }

    tooltipsEvent(): void {
        $('body').on('touchend', '.js-tooltip__trigger', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).closest('.tooltip').addClass('open');
        });
        $('body').on('touchend', function (e) {
            $('.tooltip').removeClass('open');
        });
    }

    slideDownEvent(): void {
        const $containerSlide = $('.js-containerSlide');
        $containerSlide.each(function () {
            const $thisContainerSlide = $(this)
                , $toggleSlide = $thisContainerSlide.find('.js-toggleContainerSlide')
                , $contentSlide = $thisContainerSlide.find('.js-contentSlide');
            $thisContainerSlide.addClass('closed');
            $contentSlide.slideUp(375);
            $toggleSlide.on('click', function (e) {
                const $this = $(this);
                e.preventDefault();
                $(this).focusout();
                $('.js-groupeSlides').each(function() {
                    $(this).find('.slideDownBlockHeader__icon').removeClass('ba__icon-top').addClass('ba__icon-bottom');
                });
                if ($thisContainerSlide.hasClass('closed')) {
                    $containerSlide.addClass('closed');
                    $(this).closest('.js-groupeSlides').find('.js-contentSlide').slideUp(375);
                    $('.js-contentSlide').css({'overflow': 'hidden'});
                    $thisContainerSlide.removeClass('closed');
                    $contentSlide.slideDown();
                    $(this).find('.slideDownBlockHeader__icon').toggleClass('ba__icon-top');
                    $(this).find('.slideDownBlockHeader__icon').toggleClass('ba__icon-bottom');
                    setTimeout(function () {
                        $contentSlide.css({'overflow': 'visible'});
                        if (typeof e.originalEvent  !== 'undefined') {
                            $('html,body').animate({scrollTop :  $this.offset().top - 100}, 800);
                        }
                    }, 700);
                } else {
                    $containerSlide.addClass('closed');
                    $contentSlide.slideUp(375);
                    $(this).find('.slideDownBlockHeader__icon').toggleClass('ba__icon-top');
                    $(this).find('.slideDownBlockHeader__icon').toggleClass('ba__icon-bottom');
                }
            });
        });

        // le premier tableau doit être déployé par défaut, SAUF s'il fait plus de 8 lignes (ne s'applique pas aux mobiles
        // où l'on ne voit qu'une ligne à la fois et où la 1ère ligne du premier tableau est toujours affichée par défaut).
        const $containersFirstSlide = $('.js-groupeSlides .js-containerSlide:first-child .js-toggleContainerSlide')
            , self = this
            , width = parseInt(self.wWidth);
        $containersFirstSlide.each(function () {
            const $this = $(this);
            if (($this.parent().parent('.js-containerSlide').find('tbody tr').length < 8) && width > 608) {
                $(this).trigger(oHelper.triggerClick);
            }
        });
    }

    carouselSync(): void {
        const self = this;
        const bigSlider = $('.carouselSync__list');
        const smallSlider = $('.carouselSync__pager');

        if (matchMedia('(max-width: 38em)').matches) {
            const optionSliderBig = {
                speed: 1000,
                nextText: '',
                prevText: '',
                infiniteLoop: false,
                pager: true
            };
            self.buildCarousel(optionSliderBig, 'diapoBig', 1, bigSlider);
            if (this.wCarrousel['diapoSmall'] !== undefined) {
                this.wCarrousel['diapoSmall'].destroySlider();
            }
            $('.carouselSync .bx-pager-item').show();
        } else {
            const optionSliderSmall = {
                minSlides: 1,
                maxSlides: 5,
                slideWidth: 140,
                slideMargin: 20,
                moveSlides: 1,
                pager: true,
                controls: false,
                speed: 1000,
                infiniteLoop: false,
                hideControlOnEnd: true,
                nextText: '<span></span>',
                prevText: '<span></span>'
            };
            self.buildCarousel(optionSliderSmall, 'diapoSmall', 1, smallSlider);
            const optionSliderBig = {
                speed: 1000,
                pager: false,
                nextText: '',
                prevText: '',
                infiniteLoop: false,
                onSlideBefore: function ($slideElement, oldIndex, newIndex) {
                    self.changeSmallSlider(smallSlider, newIndex);
                }
            };
            self.buildCarousel(optionSliderBig, 'diapoBig', 1, bigSlider);
            self.linkToBigSlider(this.wCarrousel['diapoBig']);
            if ($('.carouselSync__pager li').length < 6) {
                $('.carouselSync .bx-pager-item').hide();
            }

        }

        if ($('.carouselSync__pager li').length < 6) {
            $('.carouselSync__pager .bx-next').hide();
        }
    }

    linkToBigSlider(bigS: any): void {
        $('ul.carouselSync__pager').on('click', 'a', function (event) {
            event.preventDefault();
            const newIndex = $(this).parent().attr('data-diapo');
            setTimeout(function () {
                bigS.goToSlide(newIndex);
            }, 350);
        });
    }

    changeSmallSlider(slider: any, newIndex: any): void {
        const $smallSilder = $('.carouselSync__pager');
        $smallSilder.find('.active').removeClass('active');
        $smallSilder.find('li[data-diapo=\'' + newIndex + '\']').addClass('active');
        if (slider.getSlideCount() - newIndex >= 4) {
            slider.goToSlide(newIndex);
        } else {
            slider.goToSlide(slider.getSlideCount() - 4);
        }
    }

    orderedList(): void {
        $('main ol').each(function (i, list) {
            $('li', list).each(function (index, item) {
                index++;
                $(item).prepend('<span aria-hidden="true" class="icon color-first">' + index + ' - </span>');
            });
        });
    }

    respImage(): void {
        const width = parseInt(this.wWidth);
        if (width < 608) {
            $('.img-resp').each(function () {
                if ($(this).attr('data-md')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-md'));
                }
            });
        } else if (width > 608 && width < 800) {
            $('.img-resp-tablet').each(function () {
                if ($(this).attr('data-md')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-md'));
                }
            });
        } else {
            $('.img-resp, .img-resp-tablet').each(function () {
                if ($(this).attr('data-hd')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-hd'));
                }
            });
        }
    }

    respImageSlide(): void {
        const width = parseInt(this.wWidth);
        if (width < 608) {
            $('.img-resp-slide').each(function () {
                if ($(this).attr('data-sd')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-sd'));
                }
            });
        } else if (width > 608 && width < 1025) {
            $('.img-resp-slide').each(function () {
                if ($(this).attr('data-md')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-md'));
                }
            });
        } else {
            $('.img-resp-slide').each(function () {
                if ($(this).attr('data-hd')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-hd'));
                }
            });
        }
    }

    respImageEngagement(): void {
        const width = parseInt(this.wWidth);
        if (width < 608) {
            $('.img-resp-engagement').each(function () {
                if ($(this).attr('data-sd')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-sd'));
                }
            });
        } else if (width > 608 && width < 800) {
            $('.img-resp-engagement').each(function () {
                if ($(this).attr('data-md')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-md'));
                }
            });
        } else {
            $('.img-resp-engagement').each(function () {
                if ($(this).attr('data-hd')) {
                    $(this).attr('src', '');
                    $(this).attr('src', $(this).attr('data-hd'));
                }
            });
        }
    }

    truncateIntroHome(): void {
        if ( $('.top__intro-text').length > 0) {
            const width = parseInt(this.wWidth);
            const widthIntro = $('.top__intro-text').outerHeight();

            if ((width < 800 && widthIntro > 170)) {
                $('.top__intro-text').css({'height': '170px'});
                $('.top__intro-overlay').show();
                $('.top__intro-readmore').css({'display': 'block'});
                $('.top__intro-readmore').on('click', function() {
                    $('.top__intro-text').css({'height': 'auto'});
                    $('.top__intro-overlay').hide();
                    $('.top__intro-readmore').hide();
                });
            }

            if (width > 800) {
                $('.top__intro-overlay').hide();
                $('.top__intro-readmore').hide();
                $('.top__intro-text').css({'height': 'auto'});
            }
        }
    }

    enhancejQueryUI(): any {
        // AUTOCOMPLETE ITEM RENDERING
        $.ui.autocomplete.prototype._renderItem = function (ul, item) {
            const term = this.term.split(' ').join('|');
            const re = new RegExp('(' + term + ')', 'gi');
            const t = item.label.replace(re, '<b>$1</b>');
            return $('<li></li>')
                .data('item.autocomplete', item)
                .append('<a>' + t + '</a>')
                .appendTo(ul);
        };
    }

    scrollTo(): void {
        $('.js-scrollTo').on('click', function (e) {
            e.preventDefault();
            const stickMenu = $('.menuDesktop').hasClass('menuDesktop--stick');
            let margin = stickMenu ? $('.menuDesktop').outerHeight() : $('.menuDesktop').outerHeight() * 2 + 40;
            if ($('.timeline').length !== 0) {
                margin += $('.timeline').outerHeight();
            }
            if (matchMedia('(max-width: 58em)').matches) {
                margin -= stickMenu ? $('.menuDesktop').outerHeight() : $('.menuDesktop').outerHeight() * 2;
            }
            oHelper.animatedScrollToAnchor($(this).attr('href'), margin);
        });
    }

    initStickyQuestion($stickyQuestion?: any): void {
        $stickyQuestion.removeClass('stickyQuestion--fixed');
        this.$topTrigger = $stickyQuestion.offset().top + $stickyQuestion.outerHeight() - parseInt(this.wHeight);
        const stickyQuestionLeft = $stickyQuestion.offset().left;
        $stickyQuestion.css({'left': stickyQuestionLeft});
    }

    stickyBSI(): void {
        const $stickyQuestion = $('.js-stickyQuestion');
        const $window = $(window);
        const $this = this;
        const width = parseInt(this.wWidth);
        if ($stickyQuestion.length > 0 && (width > 1024)) {
            this.initStickyQuestion($stickyQuestion);
            let ID_Timeout_resize_scrollStickyQuestion;
            $window.on('resize', function () {
                clearTimeout(ID_Timeout_resize_scrollStickyQuestion);
                ID_Timeout_resize_scrollStickyQuestion = setTimeout(() => $this.initStickyQuestion(), 100);
            });

            const onScroll_stickyQuestion = function () {
                const currentScroll = $(window).scrollTop();
                if (currentScroll > $this.$topTrigger) {
                    $stickyQuestion.addClass('stickyQuestion--fixed');
                } else {
                    $stickyQuestion.removeClass('stickyQuestion--fixed');
                }
            };
            onScroll_stickyQuestion();
            if (oHelper.isTouch) {
                $window.on('touchmove', onScroll_stickyQuestion);
            }
            $window.on('scroll', onScroll_stickyQuestion);
        }
    }

    tabSize(): void {
        // TAB ITEM
        $('.tab-item').each(function () {
            const tabItem = $(this);
            let tabItemContentHeight = $('.tab-item-content', tabItem).outerHeight(true);
            // FORM PANEL
            $('.form-panel').each(function () {
                const formPanel = $(this);
                let formPanelContentHeight = $('.form-panel-content', formPanel).outerHeight(true);
                // TOGGLE
                const toggleWrapper = formPanel.find('.toggle-wrapper');
                if (toggleWrapper.hasClass('open')) {
                    const toggleWrapperContentHeight = $('.toggle-content', toggleWrapper).outerHeight(true);
                    toggleWrapper.css('max-height', toggleWrapperContentHeight);
                    tabItemContentHeight = tabItemContentHeight + toggleWrapperContentHeight;
                    formPanelContentHeight = formPanelContentHeight + toggleWrapperContentHeight;
                }
                // PANEL
                if (formPanel.hasClass('open')) {
                    formPanel.css('max-height', formPanelContentHeight);
                    tabItemContentHeight = tabItemContentHeight + formPanelContentHeight;
                }
            });
            if (tabItem.hasClass('active')) {
                tabItem.css('max-height', tabItemContentHeight);
            }
        });
    }

    truncate(selector): void {
        $(selector).each(function () {
            const fullText = $(this).attr('data-fulltext'),
                mobileLength = parseInt($(this).attr('data-length-mobile')),
                tabletLength = parseInt($(this).attr('data-length-tablet')),
                desktopLength = parseInt($(this).attr('data-length-desktop')),
                customLength = parseInt($(this).attr('data-length-custom'));

            if (fullText !== undefined) {
                const fullTextLength = fullText.length;
                let truncatedText: string;
                let hasHyphen = true;
                if (matchMedia('(max-width: 38em)').matches && mobileLength) {
                    // Mobile truncate
                    if (fullTextLength < mobileLength) {
                        hasHyphen = false;
                    }
                    truncatedText = fullText.slice(0, mobileLength);
                } else if (matchMedia('(max-width: 58em)').matches && tabletLength) {
                    // Tablet truncate
                    if (fullTextLength < tabletLength) {
                        hasHyphen = false;
                    }
                    truncatedText = fullText.slice(0, tabletLength);
                } else if (matchMedia('(max-width: 76em)').matches && desktopLength) {
                    // Tablet truncate
                    if (fullTextLength < desktopLength) {
                        hasHyphen = false;
                    }
                    truncatedText = fullText.slice(0, desktopLength);
                } else {
                    // Custom or no truncate
                    if (customLength) {
                        if (fullTextLength < customLength) {
                            hasHyphen = false;
                        }
                        truncatedText = fullText.slice(0, customLength);
                    } else {
                        truncatedText = fullText;
                        hasHyphen = false;
                    }
                }
                if (hasHyphen) {
                    if (selector === '.landing__intro > p') {
                        truncatedText = truncatedText + '<span class="landing__intro-readmore">' + 'Afficher plus' + '</span>';
                    } else {
                        truncatedText = truncatedText + '...';
                    }
                }
                // Update Selector text with Truncated content
                $(this).html(truncatedText);
            }

        });
    }

    replaceControlSlider(selector: any): void {
        if (selector !== null && selector !== undefined) {
            selector.find('.bx-controls').prepend(selector.find('.bx-prev'));
            selector.find('.bx-controls').append(selector.find('.bx-next'));
        }
    }

    viewMoreBlockLanding(): void {
        $('.block__view-more').on('click', function() {
            $(this).toggleClass('closed').siblings('.block__content').toggle();
        });
    }

    viewMoreIntroLanding(): void {
        const width = parseInt(this.wWidth);
        const wWidthIncomplete = $('.landing__intro--incomplete').outerHeight(true) - 10 + 'px';
        $('.landing__intro-readmore').on('click', function() {
            if (!$(this).hasClass('open')) {
                $('.landing__intro--complete').show();
                $('.landing__intro--incomplete').hide();
                $('.landing__intro').addClass('open');
                if (width < 800) {
                    $('.landing__blocks').css('margin-top', '-' + wWidthIncomplete);
                }
            } else {
                $('.landing__intro--complete').hide();
                $('.landing__intro--incomplete').show();
                $('.landing__intro').removeClass('open');
                $('html,body').animate({scrollTop: 0}, 800);
                $('.landing__blocks').css('margin-top', '50px');
            }
        });
    }

    slideLanding(): void {
        const width = parseInt(this.wWidth);
        const self = this;
        const num = 1;

        if (width < 800 && self.wCarrousel['landing'] === undefined) {
            self.wCarrousel['landing'] = (<any>$('.landing__slider')).imagesLoaded(function () {
                if ($('.landing__slider-item').length > 1) {
                    (<any>$('.landing__slider')).bxSlider({
                        minSlides: num,
                        maxSlides: num,
                        pager: true,
                        controls: false,
                        touchEnabled: matchMedia('(max-width: 32em)').matches,
                        infiniteLoop: true
                    });
                }
            });
        }
    }
}
const bayerAgri: BayerAgri = new BayerAgri();

